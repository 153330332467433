<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Редактирование страны</v-card-title>
          <v-card-text>
            <v-form>
              <v-row class="row-d-block">
                  <v-subheader>Название</v-subheader>
                  <v-text-field
                      v-model="country.name"
                      placeholder="Страна"
                      type="text"
                      outlined/>
              </v-row>
              <v-row class="row-d-block">
                  <v-subheader>Склонения (в стране, нет страны)</v-subheader>
                  <v-text-field
                      v-model="country.name_of"
                      placeholder="В стране"
                      type="text"
                      outlined/>
              </v-row>
              <v-row class="row-d-block">
                  <v-subheader>Метрики</v-subheader>
                  <v-textarea
                      v-model="country.metrics"
                      dense
                      rows="10"
                      placeholder="Метрика"
                      type="text"
                      outlined/>
              </v-row>
              <v-row class="row-d-block">
                  <v-subheader>Валюта</v-subheader>
                  <v-text-field
                      v-model="country.currency"
                      dense
                      placeholder="Валюта"
                      type="text"
                      outlined/>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="loading" @click="updateCountry">Сохранить</v-btn>
            <v-btn :loading="loading" @click="updateCountry(()=>{$router.push('/countries/')})">
              <router-link to="/regions/">Сохранить и выйти</router-link>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Города {{country.name_of[1]}}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
            />
          </v-card-title>
          <v-card-actions>
            <v-btn @click="createCity">Добавить город</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-data-table data-app
                          :headers="headers"
                          :items="cities ? cities : []"
                          item-key="id"
                          :search="search"
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          @click:row="editCity"
                          :footer-props="{'items-per-page-options':[-1]}"
                          mobile-breakpoint="0"
            >
              <template v-slot:item.index="{item, index}">
                <td>{{index+1}}</td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <CreateCity ref="CreateCityModal"/>
  </v-container>
</template>

<script>
import {Server} from "@/api/server";
import CreateCity from "@/views/countries/CreateCity";

const server = new Server();
export default {
  name: "EditRegion",
  components: {
    CreateCity
  },
  data() {
    return {
      loading: false,
      search: '',
      country_id: this.$route.params.country_id,
      headers: [
        {text: '№', value: 'index'},
        {text: 'Код', value: 'code'},
        {text: 'Город', value: 'city'},
        {text: 'Адрес', value: 'address'},
      ],
      country: {name_of:[]},
      cities: null,
      updatedCountryData: {},
    }
  },
  methods: {
    getCountry() {
      server.request(`cities/countries/${this.country_id}`, {}, (data) => {
        this.country = data.response;
        console.log('country', data)
      })
    },
    getCities() {
      server.request('cities/get/', {country: this.country_id}, (data) => {
        this.cities = data.response;
      })
    },
    editCity(city) {
      this.$router.push(`/countries/${this.country_id}/${city.id}`);
    },
    updateCountry(callback = null) {
      this.loading = true;
      console.log('updated country', this.country)
      server.request(`cities/updateCountry/${this.country_id}`, this.country, (data) => {
        this.loading = false;
        if (callback) callback();
        this.$root.notify('Страна сохранена', 'success')
      }, (data) => {
        this.loading = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    createCity() {
      const modal = this.$refs.CreateCityModal;
      modal.country_id = this.country_id
      modal.open();
    },
  },
  mounted() {
    this.getCountry()
    this.getCities()
  }
}
</script>

<style scoped>

</style>