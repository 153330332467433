<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Создание</v-card-title>
      <v-card-text>
        <v-form ref="createCityForm" lazy-validation>
          <v-row class="row-d-block">
              <v-subheader>Город (надо выбрать один из предложенных)</v-subheader>
              <v-autocomplete
                  :loading="loadingCities"
                  v-model="newCity"
                  :items="cities"
                  hide-no-data
                  item-text="name"
                  return-object
                  :search-input.sync="searchCities"
                  :no-filter="true"
                  outlined
                  required
                  :rules="[v=>!!v || 'Введите название города']"/>
          </v-row>
          <v-row class="row-d-block">
              <v-subheader>Код (изменить будет нельзя!)</v-subheader>
              <v-text-field
                  v-model="code"
                  outlined
                  required
                  counter="8"
                  :rules="[
                        v=>(v && v.length >= 2 && v.length < 8) || 'Длина кода должна быть не менее 3-х символов и не более 8-ми',
                        v=>/[a-z]/g.test(v) || 'Код должен быть на английском языке']"/>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateCity",
  data() {
    return {
      loading: false,
      loadingCities: false,
      searchCities: null,
      opened: false,
      country_id: null,
      newCity: {},
      code: '',
      cities: [],
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    onToggle() {
      this.newCity = {}
      this.cities = []
    },
    findCity(city) {
      this.loadingCities = true;
      this.$store.state.server.request('cities/findCity', {q: city, country: this.country_id}, (data) => {
        this.cities = data.response;

        console.log('cities', data.response)
        this.loadingCities = false
      }, () => {
        this.loadingCities = false
      })
    },
    save() {
      if (this.$refs.createCityForm.validate()) {
        this.loading = true;
        console.log('loading true');
        this.newCity.code = this.code;
        this.newCity.city = this.newCity.name;
        this.newCity.country = this.country_id
        if (!this.newCity.city) {
          this.loading = false;
          return this.$root.notify('Выберите город из списка', 'error');
        }
        console.log(this.newCity);
        this.$store.state.server.request(`cities/create`, this.newCity, (data) => {
          console.log('data', data);
          this.$router.push(`/countries/${this.country_id}/${data.response.id}`)
          this.$root.notify('Город добавлен', 'success')
          this.loading = false
          this.opened = false;
          console.log('loading false1');
        }, (data) => {
          if (data && data.error) this.$root.notify(data.error, 'error')
          this.loading = false
          console.log('loading false2');
        })
      }
    }
  },
  watch: {
    searchCities(val) {
      val && this.findCity(val)
    },
  }
}
</script>

<style scoped>

</style>
